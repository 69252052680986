<template>
   
      <div class="row">
        <div class="col-12">
          <div class="position-relative">
                <div class=" modal-button mt-2">
                  <b-button
                    type="button"
                    class="btn btn-success"
                    size="md"
                    @click="showModalCreate"
                  >
                     Add New Skill
                     <i class="mdi mdi-plus me-1"></i>
                  </b-button>
                </div>
          </div>
        </div>
        
        <div id="table-ecommerce-shops">
                <div role="complementary" class="gridjs gridjs-container">
                  <div class="gridjs-head">
                    <div class="gridjs-search">
                      <input 
                        placeholder="Type a keyword..." 
                        aria-label="Type a keyword..."  
                        class="gridjs-input gridjs-search-input" 
                        v-model = "search"
                        type="text"
                        />
                    </div>
                  </div>
                  <div class="gridjs-wrapper" style="height: auto">
                  <div v-if="success != false"
                      class="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                    {{`Skill ${success}`}}
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        @click="unSubscribeSuccess"
                  ></button>
                  </div>
                    <table role="grid" class="gridjs-table table table-hover" style="height: auto;width: 100%;">
                      <thead class="gridjs-thead">
                        <tr class="gridjs-tr">
                          <th
                            data-column-id="brand"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 78px; width: 40px"
                          >
                            <div class="gridjs-th-content">Id</div>
                          </th>
                          <th
                            data-column-id="name"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 44px; width: 150px"
                          >
                            <div class="gridjs-th-content">Title</div>
                          </th>
                          <th
                            data-column-id="email"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 06px; width: 188px"
                          >
                            <div class="gridjs-th-content">Category</div>
                          </th>
                    
                          <th
                            data-column-id="email"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 06px; width: 188px"
                          >
                            <div class="gridjs-th-content">Slug</div>
                          </th>
                  
                        
                          <th
                            data-column-id="date"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 98px; width: 127px"
                          >
                            <div class="gridjs-th-content">Updated</div>
                          </th>
                          <th
                            data-column-id="product"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 91px; width: 50px"
                          >
                            <div class="gridjs-th-content">Action</div>
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody class="gridjs-tbody">
                        
                        <tr v-for="(item, i) in category" :key="i" class="gridjs-tr">
                          
                          <td  class="gridjs-td">
                            <span>
                              <h5 class="font-size-15">{{item.id}}</h5>
                            </span>
                          </td>
                          <td class="gridjs-td">
                            {{item.title}}
                          </td>
                       
                          <td class="gridjs-td">
                            {{item.category}}
                          </td>
                         
                          <td  class="gridjs-td">
                            {{item.slug}}
                          </td>

                          <td data-column-id="product" class="gridjs-td">{{formatDate(item.updated_at)}}</td>
                          <td data-column-id="action" class="gridjs-td">
                            <span>
                             <div class="dropdown">
                                  <a href="#" class="dropdown-toggle card-drop" data-bs-toggle="dropdown" aria-expanded="false"><i class="mdi mdi-dots-vertical font-size-18"></i></a>
                                  <ul class="dropdown-menu dropdown-menu-end" style="">
                                    <li><a href="javascript:void(0)" class="dropdown-item" @click="showModalEdit(item)"><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit</a></li>
                                    <li><a href="javascript:void(0)" class="dropdown-item" @click="deleteInclusion(item.id)"><i class="mdi mdi-trash-can font-size-16 text-danger me-1"></i> Delete</a></li></ul>
                                  </div>
                            </span>
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
  
                </div>
              </div>
              <div class="col-10">
                <b-pagination
                            class="text-righ"
                            v-model="currentPage"
                            :total-rows="pagination.rows"
                            :per-page="pagination.perPage"
                            :limit="5"
                            @update:modelValue="changePage"
                  ></b-pagination>
              </div>
              <div class="col-2 ">
                <div class="row text-right">
                <label class="col-md-6 col-form-label"># of Rows</label>
                <div class="col-md-6">
                  <select  v-model="rows" @change="changeRows" class="form-select">
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                  </select>
                </div>
              </div>
              </div>
      </div>
  
      <!-- end row -->
      <skill-create :modalShow="modalCreate" @modalClose ="hideModal"></skill-create>
      <skill-edit  :modalShow="modalEdit"  @modalClose ="hideModal" :field="edit"></skill-edit>
  </template>
  <script>
    import skillCreate from "./skillCreate.vue";
    import skillEdit from "./skillEdit.vue";
    import { useStore } from 'vuex'
    import {ref,computed,reactive,onMounted,watch} from 'vue';
    import { useDebouncedRef } from "../../../../../components/utility/debounce";
    import moment from 'moment'
    import Swal from 'sweetalert2'
  
    export default {
      components:{
        skillCreate,
        skillEdit
      },
      setup() {
        const modalCreate = ref(false)
        const modalEdit = ref(false)
        const search = useDebouncedRef('',400)
        const edit = reactive({
          id:null,
          tag:''
        })
        const rows = ref(15)

        const currentPage = ref(1)
       
        const store = useStore()
        const showModalCreate = () =>{
          modalCreate.value = true;
        }
        const showModalEdit = (index) =>{
          Object.assign( edit,{id:index.id,title:index.title});
  
          modalEdit.value = true;
        }
        function hideModal(){
          modalCreate.value = false;
          modalEdit.value = false;
        }
        const success = computed(()=>{
            return store.getters['jobSettings/success']
        });
        const category = computed(()=>{
            return store.getters['jobSettings/getSkills']
        });
        const pagination = computed(()=>{
            return store.getters['jobSettings/getPagination']
        });
        const  spinner =  computed(() =>{ return  store.getters['jobSettings/processingData']})
  
        const unSubscribeSuccess = () =>{
           store.commit('jobSettings/setSuccess',false)
        }
  
        function changePage(){
           store.dispatch('jobSettings/getSkills',{page:currentPage.value,search:''});
        }
        function formatDate(date) {
            return moment(date).format("MMMM Do YYYY, h:mm:ss a");
        }
        function deleteInclusion(id){
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this action!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes, delete it!"
          }).then(result => {
            if (result.value) {
              store.dispatch('jobSettings/deleteSkills',id);
              store.dispatch('jobSettings/getSkills',{page:currentPage.value,search:search.value,rows:rows.value});
            }
          });
        }
        function changeRows(){
            store.dispatch('jobSettings/getSkills',{page:1,search:search.value,rows:rows.value});
        }
        onMounted(() => {
             store.dispatch('jobSettings/getSkills',{page:1,search:'',rows:rows.value});
        })
        watch(search , newSearch=>{
            store.dispatch('jobSettings/getSkills',{page:1,search:newSearch,rows:rows.value});
        })
        return {
          rows,
          changeRows,
          showModalCreate,
          showModalEdit,
          formatDate,
          edit,
          deleteInclusion,
          search,
          changePage,
          unSubscribeSuccess,
          success,
          modalCreate,
          modalEdit,
          hideModal,
          category,
          spinner,
          pagination,
          currentPage
        };
      }
    };
    </script>