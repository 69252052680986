<template>
    <div>
      <b-modal  v-model="mode" centered 
           no-close-on-esc
           no-close-on-backdrop 
           hide-header-close 
           hide-footer class="" >
          
           <form  @submit.prevent="submitHandler">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="inclusion"
                    >New Skill Category</label
                  >
                  <input
                    type="text"
                    v-model="state.title"
                    class="form-control"
                    @blur="v$.title.$touch()"
                    :class="v$.title.$errors.length > 0 || error ? 'is-invalid' : ''"
                    placeholder="Enter Skill"
                    id="inclusion"
                  />
                  <div class="invalid-feedback"  v-for="error of v$.title.$errors" :key="error.$uid">{{`Title ${error.$message}`}}</div>  
                  <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="inclusion"
                    >Associate Category</label
                  >
                  <v-select    
                    placeholder="Select associated category" 
            
                    v-model="state.category" 
                    :options="skillCategory" 
                    label="title" 
                  >
                  </v-select>
                  <div class="invalid-feedback"  v-for="error of v$.category.$errors" :key="error.$uid">{{`Currency ${error.$message}`}}</div>  
                  <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
                </div>
              </div>
           
                <div class="col-12 text-end">
                  <button
                    type="button"
                    class="btn btn-danger me-1"
                    @click="closeMode"
                  >
                    <i class="bx bx-x me-1"></i> Cancel
                  </button>
                 
                  <button-spinner 
                      className='btn btn-success' 
                      typeName="submit" 
                      :loading="spinner ? true : false" 
                      :disableValue="spinner ? true : false" 
                      buttonName="Submit"/>
                </div>
              </div>
          </form>
      </b-modal>

  
  </div>
</template>

<script>
import {computed,reactive,onMounted} from 'vue';
import useVuelidate from '@vuelidate/core'
import { required,helpers } from '@vuelidate/validators'
import { useStore } from 'vuex'
import buttonSpinner from '../../../../../components/utility/button-spinner'
import "vue-select/dist/vue-select.css";

import vSelect from 'vue-select'

export default {
  props:{
    modalShow:{
      type:Boolean,
      default:false
    }
  },
  components:{
    buttonSpinner,
    vSelect
  },  
  setup(props,{ emit }) {
    const store = useStore();
    const mode = computed(()=>{
        return  props.modalShow
    })
  
    const closeMode = () =>{
        emit('modalClose')
    }
    const state = reactive({
      title:null,
      category:''
    })
    const rules = {
      title: { required: helpers.withMessage('field cannot be empty', required)},
      category: { required: helpers.withMessage('field cannot be empty', required)},

    }
    const v$ = useVuelidate(rules, state)
    const  skillCategory =  computed(() =>{ return  store.getters['jobSettings/getSkillCategoryList'] || [] })
    const  spinner =  computed(() =>{ return  store.getters['jobSettings/processingData']})
    const  error =  computed(() =>{ return  store.getters['jobSettings/error']})

    async function submitHandler(){
      const result =await v$.value.$validate()
      if (!result) {
        return
      }else{
        await store.dispatch('jobSettings/addSkills', state)
        if(error.value === false) {
            emit('modalClose')
        }
        store.dispatch('jobSettings/getSkills',{page:1,search:'',rows:15});

      }
    }
    onMounted(()=>{
      store.dispatch('jobSettings/getSkillCategoryList');
    })
    return {
      spinner,
      error,
      mode,
      closeMode,
      state, 
      v$,
      skillCategory,
      submitHandler
      
    }
  }
}
</script>

<style>

</style>