<template>
    <div>
      <b-modal  v-model="mode" centered 
           no-close-on-esc
           no-close-on-backdrop 
           hide-header-close 
           hide-footer class="" >
          
          <form  @submit.prevent="submitHandler">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="inclusion"
                    >Position</label
                  >
                  <input
                    type="text"
                    v-model="state.position"
                    class="form-control"
                    @blur="v$.position.$touch()"
                    :class="v$.position.$errors.length > 0 || error ? 'is-invalid' : ''"
                    placeholder="Enter Position"
                    id="position"
                  />
                  <div class="invalid-feedback"  v-for="error of v$.position.$errors" :key="error.$uid">{{`Position ${error.$message}`}}</div>  
                  <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="inclusion"
                    >Minimum Salary</label
                  >
                  <input
                    type="text"
                    v-model="state.minimum_salary"
                    class="form-control"
                    @blur="v$.minimum_salary.$touch()"
                    :class="v$.minimum_salary.$errors.length > 0 || error ? 'is-invalid' : ''"
                    placeholder="Enter Minimum Salary"
                    id="inclusion"
                  />
                  <div class="invalid-feedback"  v-for="error of v$.minimum_salary.$errors" :key="error.$uid">{{`Minimum salary ${error.$message}`}}</div>  
                  <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="inclusion"
                    >Currency</label
                  >
                  <select 
                      v-model="state.currency" 
                       class="form-select"  
                       @blur="v$.currency.$touch()"
                      :class="v$.currency.$errors.length > 0 || error ? 'is-invalid' : ''"
                  >
                      <option v-for="item in options" :key="item.text" :value="item.value">
                        {{ item.text }}
                      </option>
                  </select>
                  <div class="invalid-feedback"  v-for="error of v$.currency.$errors" :key="error.$uid">{{`Currency ${error.$message}`}}</div>  
                  <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="inclusion"
                    >Associate Tags</label
                  >
                  <v-select    
                    placeholder="Select associated tags" 
                    
                    multiple
                    v-model="state.tags" 
                    :options="tags" 
                    label="tag" 
                  >
                  </v-select>
                  <div class="invalid-feedback"  v-for="error of v$.currency.$errors" :key="error.$uid">{{`Currency ${error.$message}`}}</div>  
                  <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
                </div>
              </div>
                <div class="col-12 text-end">
                  <button
                    type="button"
                    class="btn btn-danger me-1"
                    @click="closeMode"
                  >
                    <i class="bx bx-x me-1"></i> Cancel
                  </button>
                 
                  <button-spinner 
                      className='btn btn-success' 
                      typeName="submit" 
                      :loading="spinner ? true : false" 
                      :disableValue="spinner ? true : false" 
                      buttonName="Submit"/>
                </div>
              </div>
          </form>
      </b-modal>

  
  </div>
</template>

<script>
import {computed,reactive,onMounted} from 'vue';
import useVuelidate from '@vuelidate/core'
import { required,helpers } from '@vuelidate/validators'
import { useStore } from 'vuex'
import buttonSpinner from '../../../../../components/utility/button-spinner'
import "vue-select/dist/vue-select.css";

import vSelect from 'vue-select'

export default {
  props:{
    modalShow:{
      type:Boolean,
      default:false
    }
  },
  components:{
    buttonSpinner,
    vSelect
  },  
  setup(props,{ emit }) {
    const store = useStore();
    const mode = computed(()=>{
        return  props.modalShow
    })
    const options = reactive([
          {value:null,text:"Select Currency"},
          {value:'usd',text:"USD"},
          {value:'cad',text:"CAD"},
          {value:'php',text:"PHP"},

    ])
    const closeMode = () =>{
        emit('modalClose')
    }
    const state = reactive({
      position:null,
      minimum_salary:null,
      currency:null,
      tags:null
    })
    const rules = {
      position: { required: helpers.withMessage('field cannot be empty', required)},
      minimum_salary: { required: helpers.withMessage('field cannot be empty', required)},
      currency: { required: helpers.withMessage('field cannot be empty', required)}
    }
    const v$ = useVuelidate(rules, state)
    const  tags =  computed(() =>{ return  store.getters['jobSettings/getTagList'] || [] })
    const  spinner =  computed(() =>{ return  store.getters['jobSettings/processingData']})
    const  error =  computed(() =>{ return  store.getters['jobSettings/error']})

    async function submitHandler(){
      console.log(state.tags)
      const result =await v$.value.$validate()
      if (!result) {
        return
      }else{
        await store.dispatch('jobSettings/addPosition', state)
        if(error.value === false) {
            emit('modalClose')
        }
        store.dispatch('jobSettings/getPosition',{page:1,search:'',rows:15});

      }
    }
    onMounted(()=>{
      store.dispatch('jobSettings/getTagList');
    })

    return {
      options,
      spinner,
      error,
      mode,
      closeMode,
      state, 
      v$,
      submitHandler,
      tags
    }
  }
}
</script>

<style>

</style>