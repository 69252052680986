<template>
    <div>
      <b-modal  v-model="mode" centered 
           no-close-on-esc
           no-close-on-backdrop 
           hide-header-close 
           hide-footer class="" >
          
           <form  @submit.prevent="submitHandler">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="inclusion"
                    >New Tag</label
                  >
                  <input
                    type="text"
                    v-model="state.tag"
                    class="form-control"
                    @blur="v$.tag.$touch()"
                    :class="v$.tag.$errors.length > 0 || error ? 'is-invalid' : ''"
                    placeholder="Enter Tag"
                    id="inclusion"
                  />
                  <div class="invalid-feedback"  v-for="error of v$.tag.$errors" :key="error.$uid">{{`Tag ${error.$message}`}}</div>  
                  <div class="invalid-feedback"  v-for="(err,i) in error" :key="i" >{{`${err}`}}</div>  
                </div>
              </div>
                
           
                <div class="col-12 text-end">
                  <button
                    type="button"
                    class="btn btn-danger me-1"
                    @click="closeMode"
                  >
                    <i class="bx bx-x me-1"></i> Cancel
                  </button>
                 
                  <button-spinner 
                      className='btn btn-success' 
                      typeName="submit" 
                      :loading="spinner ? true : false" 
                      :disableValue="spinner ? true : false" 
                      buttonName="Submit"/>
                </div>
              </div>
          </form>
      </b-modal>

  
  </div>
</template>

<script>
import {computed,reactive} from 'vue';
import useVuelidate from '@vuelidate/core'
import { required,helpers } from '@vuelidate/validators'
import { useStore } from 'vuex'
import buttonSpinner from '../../../../../components/utility/button-spinner'


export default {
  props:{
    modalShow:{
      type:Boolean,
      default:false
    }
  },
  components:{
    buttonSpinner
  },  
  setup(props,{ emit }) {
    const store = useStore();
    const mode = computed(()=>{
        return  props.modalShow
    })
  
    const closeMode = () =>{
        emit('modalClose')
    }
    const state = reactive({
      tag:null
    })
    const rules = {
      tag: { required: helpers.withMessage('field cannot be empty', required)},

    }
    const v$ = useVuelidate(rules, state)
    const  spinner =  computed(() =>{ return  store.getters['jobSettings/processingData']})
    const  error =  computed(() =>{ return  store.getters['jobSettings/error']})

    async function submitHandler(){
      console.log(state.tags)
      const result =await v$.value.$validate()
      if (!result) {
        return
      }else{
        await store.dispatch('jobSettings/addTags', state)
        if(error.value === false) {
            emit('modalClose')
        }
        store.dispatch('jobSettings/getTags',{page:1,search:'',rows:15});

      }
    }
 
    return {
      spinner,
      error,
      mode,
      closeMode,
      state, 
      v$,
      submitHandler
      
    }
  }
}
</script>

<style>

</style>