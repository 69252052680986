
    
    <template>
      <Layout :pagetitle="`Settings | ${title}`">
        <div class="row">
            <div class="col-12">
                <div class="email-leftbar  mt-3">
                    <div class="card p-0 overflow-hidden mt-5">
                        <div class="mail-list ">
                            <a href="javascript:void(0)" @click="navigate('positions')" :class="data =='positions' ?  'active bg-soft-primary' : 'border-bottom'">
                                <div class="d-flex align-items-center">
                                    <i class="mdi mdi-briefcase font-size-20 align-middle me-3"></i>
                                    <div class="flex-grow-1">
                                        <h5 class="font-size-14 mb-0">Job Positions</h5>
                                    </div>
                                    
                                </div>
                            </a>
                            <a href="javascript:void(0)" @click="navigate('tags')" :class="data == 'tags' ?  'active bg-soft-primary' : 'border-bottom'">
                                <div class="d-flex align-items-center">
                                    <i class="mdi mdi-bookmark font-size-20 align-middle me-3"></i>
                                    <div class="flex-grow-1">
                                        <h5 class="font-size-14 mb-0">Tags</h5>
                                    </div>
                                    <div class="flex-shrink-0">
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0)" @click="navigate('requirements')" :class="data == 'requirements' ?  'active bg-soft-primary' : 'border-bottom'">
                                <div class="d-flex align-items-center">
                                    <i class="mdi mdi-briefcase-outline font-size-20 align-middle me-3"></i>
                                    <div class="flex-grow-1">
                                        <h5 class="font-size-14 mb-0">Job Requirements</h5>
                                    </div>
                                    <div class="flex-shrink-0">
                                    </div>
                                </div>
                            </a>

                            <a href="javascript:void(0)" @click="navigate('skills')" :class="data =='skills' ?  'active bg-soft-primary' : 'border-bottom'">
                                <div class="d-flex align-items-center">
                                    <i class="mdi mdi-briefcase-variant-outline font-size-20 align-middle me-3"></i>
                                    <div class="flex-grow-1">
                                        <h5 class="font-size-14 mb-0">Job Skills</h5>
                                    </div>
                                    <div class="flex-shrink-0">
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0)" @click="navigate('skills-category')" :class="data =='skills-category' ?  'active bg-soft-primary' : 'border-bottom'">
                                <div class="d-flex align-items-center">
                                    <i class="mdi mdi-briefcase-variant font-size-20 align-middle me-3"></i>
                                    <div class="flex-grow-1">
                                        <h5 class="font-size-14 mb-0">Job Skills Category</h5>
                                    </div>
                                    <div class="flex-shrink-0">
                                    </div>
                                </div>
                            </a>

                          

                            
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                        <position-layout v-if="data ==='positions'"></position-layout>
                        <requirements-layout v-if="data ==='requirements'"></requirements-layout>
                        <tags-layout v-if="data ==='tags'"></tags-layout>
                        <skill-category-layout v-if="data ==='skills-category'"></skill-category-layout>
                        <skill-layout v-if="data ==='skills'"></skill-layout>
                </div>
            </div>
        </div>
      </Layout>
    </template>
    <script>
        import Layout from "../../../layouts/main";
        import { useRouter,useRoute } from 'vue-router';
        import {onMounted,ref} from 'vue';
        import PositionLayout from './positions/positionLayout'; 
        import RequirementsLayout from './requirements/requirementsLayout'; 
        import SkillCategoryLayout from './skillCategory/skillCategoryLayout'; 
        import SkillLayout from './skills/skillLayout'; 
        import TagsLayout from './tags/tagsLayout'; 
        export default {
          components: {
            Layout,
            PositionLayout,
            RequirementsLayout,
            TagsLayout,
            SkillCategoryLayout,
            SkillLayout
          },
          setup(){
            const route = useRoute();
            const router = useRouter();
            const data = ref('')
            const title = ref('')
            function changeTitle(param){
                switch (param) {
                    case 'positions':
                        return 'Job Positions'
                    case 'requirements':
                        return 'Job Requirements'
                    case 'skills':
                        return 'Job Skills'
                    case 'skills-category':
                        return 'Job Skills Category'    
                    case 'tags':
                        return 'Job Tags'
                    default:
                        return 'Select Section'

                }
            }
            function navigate(param){
                    router.replace({ name: 'jobManagementSettings', params: { page: param } })
                    data.value = param
                    title.value = changeTitle(param)
            }       
            onMounted(()=>{
                data.value = route.params.page
                title.value = changeTitle(route.params.page)
            })
            return{
                navigate,
                data,title
            }
          }
        };
    </script>